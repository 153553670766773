@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeue/HelveticaNeueLt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeue/HelveticaNeueIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeue/HelveticaNeueMed.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.row {
  margin-right: -0.5em;
  margin-left: -0.5em;
}

a:hover {
  text-decoration: none !important;
  color: unset !important;
}

.no-padding {
  padding: 0 !important;
}

.app-main {
  height: 100vh;
}
.app-main__outer {
  height: 100%;
  overflow: auto;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.with-right-border {
  border-right: 1px solid #e0e0e0;
}

.btn-icon .icon-only {
  margin: 0;
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3.5px;
  position: relative;
  margin: 1em 0em 0.5em 0;
}
.main-container-title {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
}
.main-container.with-border {
  border: 1px solid #e6e6e6;
}

.scrollable {
  flex-grow: 1;
  overflow: auto;
  /* for Firefox */
  min-height: 0;
}
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  display: flex;
  flex: 1;
  overflow-y: hidden;
  color: rgb(25, 35, 53);
  letter-spacing: 0.6px;
}
.app-main__outer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.app-main__outer::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(245, 245, 245, 0.2);
}

.app-main__outer::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}

.tree-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.178);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.178);
  background-color: #f5f5f5;
}

.tree-container::-webkit-scrollbar {
  width: 3px;
  background-color: rgba(245, 245, 245, 0.2);
}

.tree-container::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}

.scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollable::-webkit-scrollbar {
  width: 3px;
  background-color: rgba(245, 245, 245, 0.2);
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}

/* Page title */
.app-page-title .mini-page-title-icon {
  font-size: 1rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0;
  margin: 0 10px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
    0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  border-radius: 0.25rem;
  width: 30px;
  height: 30px;
  justify-content: center;
}
.page-title-actions {
  width: 20%;
}

.upload-progress-bar {
  min-width: 200px;
  width: 50%;
}
.bg-upload-progress-bar-color {
  background-color: #31a66a;
}

/* Header User Info */
.header-user-info {
  text-align: end;
}
.new-element-button {
  width: fit-content;
  height: fit-content;
}

/* List filters */
.list-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.list-filters-container .search-input {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.list-filters-container .list-filters-left {
  display: flex;
  align-items: center;
}
.list-filters-container .right-part .filter {
  display: flex;
  flex-direction: column;
  margin-right: 1.5em;
}
.list-filters-container .right-part .filter .filter-title {
  color: rgb(139, 139, 139);
  font-size: 0.9em;
}
.list-filter-select-input {
  width: 100%;
  height: 25px;
  font-weight: 600;
  border: 0;
  transition: "box-shadow .1s ease,border-color .1s ease";
  background-color: transparent;
  font-size: 0.9em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.list-filter-select-input::after {
  display: none;
}
.list-filter-select-input:focus {
  border: 0;
  outline: none;
}
.list-filter-select-input:active {
  border: 0;
  outline: none;
}
.list-filters-container > .search-input {
  width: 30%;
}

.list-filters-container > .search-input > .search-input-title {
  color: rgb(139, 139, 139);
  font-size: 0.9em;
}

.filters-layout-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2em;
}
.layout-choice-icon {
  font-size: 30px;
  cursor: pointer;
}

/* TABLES LISTS */

.list-no-result {
  width: 100%;
  font-size: 0.8em;
  text-align: center;
  margin-top: 3em;
}
.list-loader {
  width: 100%;
  text-align: center;
  margin-top: 2em;
}
/* Line element */
.main-list-container {
  margin-bottom: 1em;
  margin-top: 1em;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -10px;
  padding-right: 10px;
  height: 100%;
}
.main-list-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.perfect-scrollbar-container {
  position: relative;
  overflow: hidden;
  padding-right: 15px;
  margin-right: -15px;
}

.main-list-container::-webkit-scrollbar {
  width: 6px;
  border-radius: 50px;
}

.main-list-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.cursor-pointer {
  cursor: pointer;
}
.line-element-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 1px solid #e6e6e6;
}
.row-link {
  height: 100%;
}
.grid-link {
  height: 100%;
  width: 100%;
}
.line-element-container:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.line-element-container.lg {
  height: 80px;
}
.line-element-container.md {
  height: 60px;
}
.line-element-container.sm {
  height: 45px;
}

.content-line {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  margin-bottom: 5px;
  border-radius: 5px;
}
.content-line.sm {
  height: 45px;
}
.content-line.lg {
  height: 70px;
}

.line-element-row {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5em 1em;
}
.line-element-content {
  padding: 0 1em;
  background-color: #f9f9f9;
  max-height: 0;
  height: auto;
  overflow-x: hidden;
  transition: max-height 0.3s ease-out;
  -webkit-box-shadow: inset 0 7px 9px -7px rgba(68, 44, 44, 0.2);
  -moz-box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
}
.line-element-content.collapsed {
  max-height: 400px !important;
  overflow-x: hidden;
  border-bottom: 1px solid #e6e6e6;
}

.element-title {
  font-weight: 600;
}

.line-element-info {
  display: flex;
  flex-direction: column;
}
.line-element-info-editable {
  display: flex;
  flex-direction: row;
}
.line-element-info-editable > .line-editable-content {
  display: flex;
  flex-direction: column;
}
.line-element-info-editable > .line-editable-actions {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.line-element-info > .label,
.line-element-info-editable > .line-editable-content > .label {
  font-size: 0.8em;
  color: #919ba2;
}
.line-edit-input {
  min-width: 100px;
  max-width: 50%;
  margin-right: 0.5em;
}
.video-time {
  width: fit-content;
  height: fit-content;
  font-size: 0.8em;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.mt-video-time {
  margin-top: 0.3em;
}
.list-thumbnail-playlist {
  display: flex;
  align-items: center;
  max-height: 100%;
}
.list-thumbnail {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.list-user-avatar {
  overflow: hidden;
  height: 100%;
}

.user-avatar {
  max-width: 100%;
  max-height: 100%;
  border-radius: 500rem;
}
.user-avatar.onUserBox {
  width: 40px !important;
  height: 40px !important;
}
.userBoxButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-changeable-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.organization-changeable-overlay {
  display: none;
  text-align: center;
  color: #fff;
  font-size: 0.8em;

  position: absolute;
  border-radius: 500rem;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.organization-changeable-container:hover > .organization-changeable-overlay {
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
}
.logo-overlay-active {
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
}

.organization-changeable-logo {
  max-width: 100%;
  max-height: 100%;
  border-radius: 500rem;
}

.list-action-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.grid-list-row {
  margin-right: -0.5em;
  margin-left: -0.5em;
  position: relative;
}
.grid-element-col {
  padding: 0.5em;
  position: initial;
}
.grid-element-col.box-shadow {
  box-shadow: rgb(153, 153, 153) 0px 5px 10px -5px;
}

.grid-thumbnail {
  width: 100%;
  border-radius: 3.5px 3.5px 0 0;
}

.grid-element-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3.5px;
  background-size: cover;
  position: relative;
  -webkit-box-shadow: 0 5px 10px -5px #999;
  -moz-box-shadow: 0 5px 10px -5px #999;
  box-shadow: 0 5px 10px -5px #999;
}
.grid-element-subcontainer {
  padding: 0.5em;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0px -6px 9px -7px rgba(0, 0, 0, 0.2);
}
.grid-element-container.collapsed {
  border: 2px solid #00add2;
  border-radius: 3.5px 3.5px 0 0;
}
.grid-selected {
  border: 2px solid #00add2;
  border-radius: 3.5px 3.5px 0 0;
}
.grid-element-content-container {
  position: absolute;
  z-index: 100;
  left: 0;
  max-height: 0;
  height: auto;
  width: 100%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  margin-bottom: 0.5em;
}
.grid-element-content-container.collapsed {
  max-height: 300px !important;
}
.grid-element-content {
  padding: 0 1em;
  border: 1px solid #d4d4d5;
  width: 100%;
  background-color: #f9f9f9;
  height: auto;
  border-radius: 0 0 3.5px 3.5px;
  -webkit-box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.2);
}
.grid-element-firstrow {
  position: relative;
}
.grid-element-firstrow > .status {
  position: absolute;
  top: -30px;
  left: 5px;
}
.grid-element-firstrow > .video-time {
  margin-top: 0 !important;
  position: absolute;
  top: -28px;
  right: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}
.grid-element-second-row {
  align-items: center;
}

.grid-element > .line-element-info > .content {
  font-size: 0.8em;
}
.list-grid-space-selector {
  margin-bottom: 1em;
}
.input-range {
  width: 100px !important;
  margin-right: 1em;
}
.input-range__track {
  height: 0.2em !important;
}
.input-range__track--active {
  background: #253553 !important;
}
.input-range__slider {
  background: #a1abb4 !important;
  border: 1px solid #a1abb4 !important;
}
.input-range-icon-big {
  margin: 0 !important;
}
.input-range__slider {
  height: 0.7rem !important;
  width: 0.7rem !important;
  margin-top: -0.45rem !important;
  border-radius: 1rem !important;
  background-color: #253553 !important;
  border-color: #253553 !important;
}

/* LIST & GRID - collapsible */
.collapsible-first-row {
  margin-bottom: 0.5em;
}
/* video */

.collapsible-container {
  padding: 1em 0;
  height: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  /* for Firefox */
  min-height: 0;
}
.collapsible-thumbnail-container {
}
.collapsible-thumbnail {
  max-height: 135px;
  width: 100%;
}
.collapsible-element-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
}
.collapsible-element-info > .label {
  font-size: 0.8em;
  color: rgb(139, 139, 139);
}

.collapsible-element-info > .content {
}
.collapsible-title-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.collapsible-title-container > .line {
  display: flex;
}
.collapsible-element-info > .link-content {
  background-color: rgb(230, 230, 230);
  font-size: 0.9em;
  width: 100%;
  padding: 2px;
}

.collapsible-element-info > .iframe-content {
  background-color: rgb(230, 230, 230);
  font-size: 0.9em;
  width: 100%;
  padding: 2px;
  word-break: break-word;
}
.collapsible-actions-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.collapsible-actions-row {
  flex-grow: 1;
}
.collapsible-action-menu {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/* Status */
.status-label {
  font-size: 0.8em;
  padding: 3px 10px;
  width: fit-content;
  color: #fff;
  border-radius: 3.5px;
}
.status-label.success {
  background-color: #31a66a;
}
.status-label.warning {
  background-color: #f7b924;
}
.status-label.info {
  background-color: #30b1ff;
}
.status-label.error {
  background-color: #d92550;
}
.status-label.unpaired {
  background-color: #6c757d;
}

/* Release status chip */
.release-status-chip {
  font-size: 0.8em;
  font-weight: 400;
  padding: 3px 10px;
  width: fit-content;
  color: #fff;
  border-radius: 3.5px;
}

.release-status-chip.unsynced {
  color: #6c757d;
  border: 1px solid #6c757d;
}

.release-status-chip.synced {
  background-color: #0774b3;
  border: 1px solid #0774b3;
}

.list-nb-elements {
  font-size: 0.7em;
  padding: 2px 10px;
  min-width: 40px;
  text-align: center;
  color: #fff;
  border-radius: 12px;
  background-color: #30b1ff;
  margin-left: 20px;
}

/* Local Tag */
.tag {
  padding: 3px;
  font-size: 10px;
  display: flex;
  font-weight: 600;
  align-items: center;
  margin-right: 2.5px;
  margin-bottom: 2.5px;
  border-radius: 3.5px;
  width: fit-content;
}
.tag.global {
  color: #747e86;
  border: 1px solid #a1abb4;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.tag > .icon-tag {
  margin-right: 2px;
}
.tag > .text {
  position: relative;
  bottom: 1px;
}

/** Forms **/
.input-text-normal {
}
.textarea-normal {
}

/* TagHandler */
.tagHandler__control {
  border: 1px solid #dededf;
  min-height: 26px !important;
}
.tagHandler__control:hover {
  border-color: #85b7d9 !important;
}
.tagHandler__control--is-focused {
  box-shadow: 0 0 0 1px #85b7d9 !important;
}
.tagHandler__multi-value {
  padding: 3px;
  font-size: 11px;
  display: flex;
  margin: 0 2px 0 0 !important;
  font-weight: 400;
  align-items: center;
  border-radius: 3.5px !important;
  width: fit-content;
  background-color: transparent !important;
}
.tagHandler__indicator {
  padding: 4px !important;
}
.tagHandler__menu {
  margin-top: 2px !important;
  z-index: 10 !important;
}

.form-error-style {
  margin-bottom: 10px;
  color: #dc0000;
  font-size: 0.7em;
}
.form-label {
  font-size: 0.8em;
  margin-bottom: 3px;
}
.form-cross-required {
  color: #c5190e;
  font-size: 1em;
  position: relative;
  bottom: 1px;
  left: 3px;
}
.form-textarea {
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  padding: 5px;
  border-radius: 4px;
}
.form-textarea:focus {
  border: 1px solid #85b7d9;
  outline: none;
}
.form-textarea.error {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}
.form-textarea[disabled] {
  opacity: 0.45;
}
.form-input-container {
  margin-bottom: 0.5em;
}
.form-switch {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-switch > .label-switch {
  float: left;
  font-size: 1em;
}

.form-switch-screenTimer {
  display: flex;
}
.form-switch-screenTimer > .label-switch {
  float: left;
  font-size: 1em;
  padding: 0 10px;
}
.form-switch-sharedswitch {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 5px;
}
.form-switch-sharedswitch > .label-switch {
  float: left;
  font-size: 0.8em;
  margin-bottom: 7px;
  margin-right: 4px;
}
.ui.fitted.toggle.checkbox.sharedSwitch > label {
  margin-bottom: 0;
}
.ui.fitted.toggle.checkbox.sharedSwitch > input {
  width: 3rem;
  height: 1rem;
}
.ui.fitted.toggle.checkbox.sharedSwitch > label::before {
  width: 3rem;
  height: 1rem;
}
.ui.fitted.toggle.checkbox.sharedSwitch > label::after {
  width: 1rem;
  height: 1rem;
}
.ui.toggle.checkbox.sharedSwitch input:checked ~ .box:before,
.ui.toggle.checkbox.sharedSwitch input:checked ~ label:before {
  background-color: #30b1ff !important;
}
.form-separator {
  margin-bottom: 1em;
  height: 2px;
  border-bottom: 1px solid #cecece;
  width: 100%;
}
.form-error {
  width: 100%;
  text-align: center;
  margin-top: 0.5em;
}

.form-video-actionbar {
  width: 100%;
  margin: 0.5em 0;
  display: flex;
  justify-content: space-between;
}
.form-video-input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  width: 1px;
  height: 1px;
}
.form-video-label {
}

#canvas {
  display: none;
}

/* Thumbnail */
.thumbnail-viewer-in-video {
  position: absolute;
  top: 5px;
  z-index: 10;
  max-width: 100px;
  right: 5px;
  border: 1px solid #e4e4e4;
}

.thumbnail-change-actions {
  display: flex;
  /* width: 50%; */
  font-size: 0.8em;
  /* flex-direction: row; */
  /* align-items: center; */
  /* padding: 0 5em; */
  /* justify-content: center; */
  text-align: center;
}
.thumbnail-change-actions > .left {
  width: 40%;
  text-align: center;
}
.thumbnail-change-actions > .center {
  width: 20%;
  text-align: center;
}
.thumbnail-change-actions > .right {
}

.form-delete-button-right {
  float: right;
}

/* VIDEO PLAYER */
.no-video-overlay {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(214, 214, 214, 0.5);
  color: #fff;
  font-size: 1em;
  position: absolute;
  top: 0;
  font-family: "Helvetica Neue";
}
.thumbnail-tip-overlay {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 1.2em;
  position: absolute;
  top: 0;
  font-family: "Helvetica Neue";
}
.flag-locale-select {
  margin-right: 0 !important;
}

.big-subtitle {
  font-size: 1.5em;
  font-weight: 200;
  margin-top: 1em;
}

.number-of-elements {
  font-size: 2em;
  display: flex;
  width: 100%;
  margin-bottom: 0.5em;
  font-weight: 600;
}

/* Tabs */
.RRT__container {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  overflow: hidden;
}
.body-tabs > .RRT__tab {
  font-size: 1.3em;
  font-weight: 200;
  color: #b5b5b5;
}
.body-tabs.body-tabs-layout {
  padding: 0 !important;
  margin-left: 0 !important;
}
.RRT__tab--selected {
  color: #013261 !important;
  border-bottom: 2px solid #013261;
}
.RRT__inkbar {
  height: 2px;
  background-color: #013261;
}
.RRT__panel {
  flex: 1 1;
  padding-top: 10px;
  margin-top: 0 !important;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  overflow: hidden;
}

.loader-button {
  margin-right: 10px !important;
}

.edit-line-item {
  font-size: 1em;
  margin-left: 0.5em;
}
.basic-multi-select {
  width: 100%;
}
.color-picker-result {
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  position: relative;
}
.colorpicker-popover {
  position: absolute;
  top: 45px;
  left: -5px;
  z-index: 10;
}
.colorpicker-cover {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.webContent-default-thumbnail {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5em;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 0.8em;
}
.thumbnail-webcontent {
  width: 100%;
}

/* DnD handler */
.droppable-list-elements {
  width: 100%;
  margin-top: 1em;
}

.droppable-list-content {
  border: 1px solid rgb(212, 212, 212);
  background-color: transparent;
  padding: 1em;
  width: 100%;
  border-radius: 3.5px;
  height: 100%;
  overflow: hidden;
  padding-bottom: 60px;
}
.Grid-DND {
  width: 600px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.gridItemWrapper {
  flex: 0 0 33.3%;
  display: flex;
  justify-content: center;
  align-items: stretch;

  box-sizing: border-box;
}
.gridItemWrapper::before {
  content: "";
  display: table;
  padding-top: 100%;
}
.gridImage {
  flex-grow: 1;
  border: 1px solid white;

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: 50%;
}
.no-margin-right {
  margin-right: 0 !important;
}
.droppable-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.line-element-container-helper {
  width: 100%;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  background-color: rgb(224, 224, 224);
  border-radius: 3.5px;
  padding: 1em 1em;
  font-size: 0.8em;
  align-items: center;
  justify-content: center;
  color: rgb(107, 107, 107);
  margin-bottom: 0.5em;
}

.draggable-tab {
  font-size: 1.1em !important;
  padding: 0.5em 0.75em !important;
}
.hiddenDraggable {
  display: none;
}
/* Playlist content label */
.droppable-content-label {
  font-size: 0.8em;
  padding: 3px 10px;
  width: fit-content;
  color: #fff;
  border-radius: 3.5px;
  float: right;
}
.droppable-content-label.video {
  background-color: #30b1ff;
}
.droppable-content-label.webContent {
  background-color: #fc8728;
}
.droppable-content-label.playlist {
  background-color: #f7b924;
}

/* Devices */
/* tree view*/
.devices-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex: 1;
  margin-left: -20px;
  margin-right: -15px;
  overflow: hidden;
}

.devices-list-container {
  min-width: 250px;
  position: relative;
  padding: 0 0 0 10px;
  flex: 1;
  display: flex;
  border-right: 1px solid #e6e6e6;
}
.devices-details-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}
.tree-container {
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
}
.tree-view {
  overflow-y: visible !important;
}
.tree-header-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2em;
}
.tree-sub-orga-0 {
  margin-left: 0;
}
.tree-sub-orga-1 {
  margin-left: 1em;
}
.tree-sub-orga-2 {
  margin-left: 2em;
}
.tree-sub-orga-3 {
  margin-left: 3em;
}
.tree-sub-orga-4 {
  margin-left: 4em;
}
.tree-anchor {
  width: 10px;
  height: 10px;
  position: relative;
  top: -4px;
  margin-right: 5px;
}
.node {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 5px;
}
.node-deviceGroup {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  font-size: 0.9em;
  color: #929292;
}
.deviceGroup-new-tree {
  background-color: transparent;
}
.deviceGroup-new-input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3.5px;
  outline: none;
  color: #585858;
  height: 100%;
  width: 100%;
  margin-right: 1em;
}
.deviceGroup-icon {
  display: block;
}
.node-deviceGroup:hover > .deviceGroup-icon {
  display: none;
}
.deviceGroup-handler {
  display: none !important;
}
.node-deviceGroup:hover > .deviceGroup-handler {
  display: block !important;
}
.deviceGroup-handler-item {
  font-size: 0.8em !important;
  color: rgb(75, 75, 75) !important;
}

.tree-orga-mine {
  background-color: var(--primary);
  font-size: 0.6em;
  padding: 0 5px;
  height: 15px;
  border-radius: 3.5px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}
.tree-view_item {
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  border-radius: 3.5px;
  cursor: pointer;
  position: relative;
}

.tree-view_item.orga-tree {
  background-color: rgba(0, 0, 0, 0.06);
}
.tree-view_item.orga-tree.active {
  background-color: rgba(0, 0, 0, 0.15);
}
.tree-view_item.orga-tree:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.tree-view_item.orga-tree.active:hover {
  background-color: rgba(0, 0, 0, 0.18);
}
.tree-view_item.deviceGroup-tree {
  background-color: rgba(0, 0, 0, 0.03);
}
.tree-view_item.deviceGroup-tree.active {
  background-color: rgba(0, 0, 0, 0.1);
}
.tree-view_item.deviceGroup-tree:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.tree-view_item.deviceGroup-tree.active:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.tree-view_item.deviceGroup-tree.hidden {
  display: none;
}
.tree-view_arrow {
  position: absolute;
  right: 5px;
}
.tree-view_arrow-collapsed {
  transform: rotate(90deg) !important;
}
.device-view-item {
  height: 25px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 0.5em;
  border-radius: 3.5px;
  cursor: pointer;
  font-size: 0.9em;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}
.device-view-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.device-view-item.active {
  background-color: #466d97;
  color: #fff;
}
.device-view-item.selected {
  background-color: rgba(0, 173, 210, 0.25);
}
.device-view-empty {
  height: 25px;
  display: flex;
  font-size: 0.8em;
  color: #b1b1b1;
}
.organization-with-logo-displayer {
  display: flex;
  height: 25px;
  align-items: center;
  font-size: 0.9em;
  font-weight: 400;
  margin-top: 0.5em;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.organization-with-logo-displayer > .orga-logo {
  margin-right: 0.5em;
}
.organization-with-logo-displayer > .orga-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.device-small-info {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.device-info-label {
  background-color: #bdbdbd;
  color: #fff;
  height: 100%;
  padding: 2px 5px;
  border-radius: 3.5px;
  text-align: center;
  font-size: 0.9em;
  margin-right: 0.5em;
}
.device-info-value {
  font-size: 0.9em;
  display: flex;
  align-items: center;
}
.screen-display {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.screen-display-element {
  border: 0;
  margin-bottom: 0.5em;
  width: calc(100% / 5);
}
.screen-display-active {
  border: 2px solid var(--primary);
}

.pairing-code-container {
  background-color: #818181;
  color: #fff;
  padding: 2px 5px;
  border-radius: 3.5px;
  text-align: center;
  font-size: 0.9em;
}

/* Sidebar */
.sidebar-editor {
  width: 400px !important;
  background-color: #fff;
  padding: 60px 1em 1em 1em;
}

/* Autocomplete */
.autocomplete-menu {
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 0;
  font-size: 90%;
  position: initial;
  overflow: auto;

  position: absolute;
  overflow: auto;
  height: fit-content;
  z-index: 100;
  top: 40px;
  width: 100%;
  left: 0;
  max-height: 200px;
}
.autocomplete-input-container {
  position: relative;
  width: 100%;
}
.autocomplete-input {
  height: 40px;
  border: 1px solid #c5c5c5;
  border-radius: 3.5px;
  padding-left: 3em;
  width: 100%;
  outline: none;
}
.search-icon-input {
  position: absolute;
  left: 5px;
  top: 10px;
  font-size: 20px;
}
.autocomplete-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 5px;
  background-color: rgb(236, 236, 236);
  margin-bottom: 0.5em;
  border-radius: 3.5px;
  font-size: 0.9em;
  justify-content: space-between;
}
.autocomplete-item:hover {
  background-color: rgb(231, 231, 231);
}
.autocomplete-item-remove {
  font-size: 1.6em;
  cursor: pointer;
}

.program-publication-button {
  width: 100%;
  position: relative;
  height: 32px;
  top: 17px;
}

.program-dates-container {
  border-radius: 3.5px;
  border: 1px solid #575f66;
  color: #575f66;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  margin-right: 2px;
}

.program-dates-container.programmed {
  font-size: 400;
  background-color: rgba(50, 166, 105, 0.22);
}
.program-dates-container > .input-date {
  width: 45%;
  border: 0;
  outline: none;
  background-color: transparent;
  text-align: center;
}
.program-dates-container > .input-date::-webkit-calendar-picker-indicator {
  padding-left: 2px;
  margin-left: 0;
}

.sharingRules-title {
  font-size: 1em;
  font-weight: 600;
  width: 100%;
  margin-bottom: 1em;
  margin-top: 1em;
}
.rule-link-explainer {
  float: right;
  font-size: 0.8em;
  color: #9a9a9a;
}
.label.success {
  color: #31a66a;
}
.label.error {
  color: #d92550;
}

/** Filter buttons **/
.filterButton {
  position: relative;
  margin-right: 5px;
}
.btn.btn-pill.btn-wide,
.btn.btn-pill {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.btn-outline-dark {
  color: #253553;
  border-color: #253553;
}

.btn-dark-blue {
  color: #fff !important;
  border: 1px solid transparent;
  background-color: #253453;
}
.btn-dark-blue:hover .btn-dark-blue:active {
  background-color: #1c2d50;
  color: #fff;
  border-color: transparent;
}
.btn-outline-dark-blue {
  color: #253453;
  border-color: #253453;
  background-color: transparent;
}

.btn-outline-dark-blue:hover .btn-outline-dark-blue:active {
  background-color: #1c2d50 !important;
  color: #fff;
  border-color: transparent;
}

.btn-grey {
  color: #283551;
  background-color: #edeef3;
  border-color: #edeef3;
}
.btn-grey:hover {
  color: #283551;
  background-color: #d9dae0;
  border-color: #d9dae0;
}
.btn-grey:focus,
.btn-grey.focus {
  box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5);
}
.btn-grey.disabled,
.btn-grey:disabled {
  color: #283551;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-grey:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #283551;
  background-color: #dedfe4;
  border-color: #dedfe4;
}
.btn-grey:not(:disabled):not(.disabled):active:focus,
.btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(130, 138, 145, 0.5);
}

.btn-outline-grey {
  color: #283551;
  border-color: #edeef3;
}
.btn-outline-grey:hover {
  color: #283551;
  background-color: #edeef371;
  border-color: #edeef3;
}
.btn-outline-grey:focus,
.btn-outline-grey.focus {
  box-shadow: 0 0 0 0 #edeef371;
}
.btn-outline-grey.disabled,
.btn-outline-grey:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-grey:not(:disabled):not(.disabled):active,
.btn-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn-outline-grey.dropdown-toggle {
  color: #283551;
  background-color: #dedfe4;
  border-color: #dedfe4;
}
.btn-outline-grey:not(:disabled):not(.disabled):active:focus,
.btn-outline-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 #edeef371;
}

.filterButton .optionsMenu {
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  line-height: 1.8em;
  min-width: 200px;
  overflow-y: auto;
  background-color: #fff;
  display: none;
  -webkit-box-shadow: 0 5px 16px #999;
  -moz-box-shadow: 0 5px 16px #999;
  box-shadow: 0 5px 16px #999;
  position: absolute;
  top: 35px;
  z-index: 10;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
}
.filterButton .optionsMenu.active {
  display: flex;
}
.filterButton .optionsMenu .filterOption.first {
  border-bottom: 1px solid #a1abb4;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  color: unset;
}
.filterButton .optionsMenu .filterOption {
  width: 100%;
  height: 25px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
  color: rgb(146, 145, 145);
}
.filterButton .optionsMenu .filterOption:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.filterButton .optionsMenu .filterOption .title {
  padding-left: 5px;
}

.list-input-search {
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  background-color: #edeef3;
  color: #343a40;
  border: 1px solid #edeef3;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
}
.list-input-search.active {
  width: 250px;
}
.list-input-search .icon-button {
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-input-search .inputText {
  background-color: transparent;
  width: 100%;
  outline: none;
  border: 0;
  text-decoration: none;
  display: none;
}
.list-input-search .inputText.active {
  display: flex;
}

.list-input-search .close-button {
  display: none;
  font-size: 2em;
  position: absolute;
  right: 6px;
  top: 3px;
}
.list-input-search.active .close-button {
  display: flex;
  width: 20px;
}

.dropzone-video {
  width: 100%;
  padding-top: 56.25%;
  border: 2px dotted #a6a6a6;
  outline: none;
  position: relative;
}
.dropzone-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tab-device-content-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.tab-device-content {
  display: flex;
  height: 25px;
  border-radius: 20px;
  padding: 0 10px;
  margin-right: 10px;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.9em;
  cursor: pointer;
}
.tab-device-content.player {
  border: 1px solid #4c5963;
  color: #4c5963;
  background-color: transparent;
}
.tab-device-content.player.active {
  color: #fff;
  background-color: #4c5963;
}

.tab-device-content.bloc1 {
  border: 1px solid #2485d9;

  color: #2485d9;
  background-color: transparent;
}
.tab-device-content.bloc1.active {
  color: #fff;
  background-color: #2485d9;
}

.tab-device-content.bloc2 {
  border: 1px solid #58d1eb;

  color: #58d1eb;
  background-color: transparent;
}
.tab-device-content.bloc2.active {
  color: #fff;
  background-color: #58d1eb;
}

.tab-device-content.bloc3 {
  border: 1px solid #36a9f3;
  color: #36a9f3;
  background-color: transparent;
}
.tab-device-content.bloc3.active {
  color: #fff;
  background-color: #36a9f3;
}

.display-parameters-image-uploader-container {
  padding: 5px;
}

.display-parameters-image-uploader {
  background-color: #f1f1f1;
  border: 1px dotted #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 7px;
}
.display-parameters-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.display-parameters-image-container > .actions-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.screenTimer-title {
  display: flex;
  height: 25px;
  border-radius: 20px;
  padding: 0 5px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.8em;
  cursor: pointer;
  border: 1px solid #4c5963;
  color: #4c5963;
  background-color: transparent;
}
.screenTimer-title.active {
  color: #fff;
  background-color: #4c5963;
}

/* TEXT EDITOR */
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  content: "Rubik" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial", cursive;
  content: "Arial" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="OpenSans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="OpenSans"]::before {
  font-family: "Open Sans", cursive;
  content: "Open Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="HelveticaNeue"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="HelveticaNeue"]::before {
  font-family: "Helvetica Neue", cursive;
  content: "Helvetica" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}

.ql-font-Arial {
  font-family: Arial;
}
.ql-font-HelveticaNeue {
  font-family: "Helvetica Neue";
}
.ql-font-OpenSans {
  font-family: "Open Sans";
}

.ui.input > input {
  padding: 0.65em 1em !important;
}

.scrollbar-extra-right {
  margin-right: -10px;
  padding-right: 10px;
}

.custom-scroll {
  overflow: auto;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  border-radius: 50px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}
.lineContentProgramDatesOK {
  background-color: #31a66a;
  border: 1px solid #31a66a;
  color: #fff;
}

/** Sidebar storage **/
.storage-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.progress-bar-storage {
  height: 12px;
  font-size: 0.7em;
  border-radius: 25px;
}
.storage-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.storage-info-progressbar {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.storage-info-header {
  color: #253553;
  font-size: 0.7em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.storage-content-remaining-low {
  color: #d92550;
}
.storage-content-remaining-medium {
  color: #f7b924;
}
.storage-content-remaining-high {
  color: #0774b3;
}
.bg-storage-info-remaining-low {
  background-color: #d92550;
}
.bg-storage-info-remaining-medium {
  background-color: #f7b924;
}
.bg-storage-info-remaining-high {
  background-color: #0774b3;
}
/******/

/** Release status progress bar storage **/
.releasestatus-progress-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.progress-bar-releasestatus {
  height: 7px;
  font-size: 0.7em;
  border-radius: 25px;
}
.releasestatus-progress-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.releasestatus-progress-info-progressbar {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.releasestatus-progress-info-header {
  margin-top: 0.2rem;
  color: #253553;
  font-size: 0.7em;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.releasestatus-progress-content-remaining-low {
  color: #d92550;
}
.releasestatus-progress-content-remaining-medium {
  color: #f7b924;
}
.releasestatus-progress-content-remaining-high {
  color: #0774b3;
}
.bg-releasestatus-progress-info-remaining-low {
  background-color: #d92550;
}
.bg-releasestatus-progress-info-remaining-medium {
  background-color: #f7b924;
}
.bg-releasestatus-progress-info-remaining-high {
  background-color: #0774b3;
}
/********/

.sidebar-close-button {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #edeef3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-close-button:hover {
  background-color: #e2e3e8;
}
.sidebar-close-button:active .sidebar-close-button:focus {
  background-color: #d6d7dc;
}
/***** Quick Access panel ****/
.quickaccess-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  position: relative;
}
.tizen-version-label {
  font-size: 0.8em;
  padding: 3px 10px;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  border-radius: 3.5px;
  margin-left: 5px;
}
.tizen-version-label.s4 {
  background-color: #31a2ff;
}
.tizen-version-label.s6 {
  background-color: #0fcec0;
}
.quickaccess-sidebar-title {
  font-size: 1.2em;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.device-name-quickaccess {
  background-color: #edeef3;
  border-radius: 0.25em;
  width: 100%;
  margin-bottom: 0.5em;
  padding: 4px 4px;
  font-size: 1em;
  display: flex;
}
.program-name-quickaccess {
  background-color: #edeef3;
  border-radius: 0.25em;
  width: 100%;
  margin-bottom: 1em;
  padding: 4px 8px;
  font-size: 1em;
  display: flex;
}

.quickaccess-timers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.quickaccess-title {
  font-weight: 600;
  margin-bottom: 4px;
}
.quickaccess-timer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.quickAccessButton {
  background-color: transparent;
  width: 100% !important;
  border-radius: 2.5em;
  margin-bottom: 1em;
  font-weight: 600;
}
.quickAccessButton {
  border-color: unset;
}
.quickAccessButton.poweron {
  border: 1px solid #93c7a8;
  color: #93c7a8;
}
.quickAccessButton:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.quickAccessButton.poweroff {
  border: 1px solid #cf3e54;
  color: #cf3e54;
}
.quickAccessButton.source {
  border: 1px solid #35435d;
  color: #35435d;
}
.quickAccessButton.restart {
  border: 1px solid #653c90;
  color: #653c90;
}
.quickAccessButton.cleardisk {
  border: 1px solid #4d5a64;
  color: #4d5a64;
}
.quickAccessButton.advanced {
  border: 1px solid #64bcfa;
  color: #64bcfa;
  margin-top: 2em;
}
.quickaccess-planning-loader {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
}
.quickaccess-power-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
}

.selectDeviceSourceContainer {
  margin-top: 1em;
  margin-bottom: 2em;
}
.selectDeviceSource__indicator {
  padding: 2px !important;
}
.selectDeviceSource__indicator-separator {
  margin-top: 2px;
  margin-bottom: 2px;
}
.selectDeviceSource__control {
  min-height: 32px !important;
  height: 32px !important;
}
.selectDeviceSource__menu {
  margin-top: 0 !important;
}

/* Replace or add content */
.ui.fullscreen.modal {
  left: unset !important;
}
.ui.fullscreen.scrolling.modal {
  left: unset !important;
}
.line-selected {
  background-color: #00add1;
}
.replacement-video-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.replace-from-to {
  margin-right: 2em;
  margin-left: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modal-subtitle {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-content: center;
  align-items: center;
}
.playlistChoice {
  width: 100%;
  margin-top: 1em;
}
.playlistChoice-buttons {
  margin-top: 1em;
}
.modal-replace-success {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  text-align: center;
}
.modal-loader {
  width: 100%;
  text-align: center;
}

.actions-button-video {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}
.ap-input {
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  padding: 0.65em 1em !important;
  height: 32px;
}
.login-background {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/loginbg.png");
  background-size: cover;
}
.login-box {
  box-shadow: 0px 9px 35px -23px rgba(148, 148, 148, 1);
  background-color: #fff;
  border-radius: 4px;
  width: 450px;
}
.login-box-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.login-box-top {
  padding: 40px 30px 30px 30px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}
.login-box-footer {
  border-top: 2px solid #e3e3e3;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 20px 30px 20px 30px;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
}
.passwordForgotten {
  color: #2374ae;
  font-weight: 600;
  display: flex;
  font-size: 0.9em;
  align-items: center;
  cursor: pointer;
}
.login-error {
  margin-top: 20px;
  color: #c72317;
}
.login-success {
  margin-bottom: 20px;
  color: #259e15;
}
.pin-dashboard {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.dashboard-resume {
  background-color: #fff;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 400px;
}
.dashboard-block {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  padding: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.dashboard-block.on {
  background-color: #3ac47d;
}
.dashboard-block.off {
  background-color: #f7b924;
}
.dashboard-block.disconnected {
  background-color: #d92550;
}
.dashboard-block.unpaired {
  background-color: #6c757d;
}
.dashboard-number {
  font-size: 2em;
  font-weight: 600;
  color: #fff;
}
.dashboard-subtitle {
  font-size: 0.8em;
  color: #fff;
}

.unlocated-devices {
  background-color: #fff;
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  top: 110px;
  left: 10px;
  min-width: 400px;
}

.copyright {
  color: #fff;
  margin-top: 2em;
  font-size: 0.9em;
}

/** Modals buttons */
.ui.modal > .actions {
  border-top: none;
  background-color: #fff;
}
.ui.negative.button,
.ui.negative.buttons .button {
  background-color: #f7f7fa;
  color: #a3b0b9;
  border: 1px solid #ececee;
}

.ui.negative.button:hover,
.ui.negative.buttons .button:hover {
  background-color: #ececee;
  color: #a3b0b9;
  text-shadow: none;
}

.ui.negative.button:active,
.ui.negative.buttons .button:active,
.ui.negative.button:focus,
.ui.negative.buttons .button:focus {
  background-color: #d6d6d6;
  color: #fff;
  text-shadow: none;
}

.ui.positive.button,
.ui.positive.buttons .button {
  background-color: #30b1ff;
  border-color: #30b1ff;
}

.ui.positive.button:hover,
.ui.positive.buttons .button:hover {
  background-color: #1ea1f3;
  border-color: #1ea1f3;
  color: #fff;
}

.ui.positive.button:active,
.ui.positive.buttons .button:active,
.ui.positive.button:focus,
.ui.positive.buttons .button:focus {
  color: #fff;
  background-color: #24abfd;
  border-color: #24abfd;
}

.btn-header-device-tree {
  height: 30px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.5px;
  font-size: 0.9em;
  cursor: pointer;
}

.btn-header-device-tree:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.btn-header-device-tree.active {
  background-color: #929ba1;
  border: 1px solid #929ba1;
  color: #fff;
}
.page-top-buttons {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.device-remote-button {
  background-color: #eeeeee;
  border: 1px solid #e0e0e0;
  float: right;
  padding: 2px 10px;
}
.device-remote-button:hover,
.device-remote-button:active {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}
.device-refresh-loader {
  margin-left: 10px !important;
}
