html {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mainWrapper {
  height: 100vh;
  /*noinspection CssUnresolvedCustomProperty*/
  height: calc(var(--vh, 1vh) * 100);
}

.mainWrapper::-webkit-scrollbar-track {
  display: none;
}

.mainWrapper::-webkit-scrollbar {
  display: none;
}

.mainWrapper::-webkit-scrollbar-thumb {
  display: none;
}

/* Page Context */
.pushable:not(body) {
  transform: none !important;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed !important;
}
.ui.radio.checkbox .box,
.ui.radio.checkbox label {
  font-size: 0.9em;
}

.librarySet {
  direction: rtl;
}
.librarySet::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.librarySet::-webkit-scrollbar {
  width: 3px;
  background-color: rgba(245, 245, 245, 0.2);
}

.librarySet::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}

/* Phone number input */
.react-tel-input input:focus {
  border-color: #85b7d9 !important;
}

.active.menuItemAdmin {
  border-bottom: 2px solid #fff;
}

/* Sidebar admin content */
.sidebarContentContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: transparent;
}

.sidebarContentContainer::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
  background-color: rgb(78, 96, 104);
}

.sidebarContentContainer::-webkit-scrollbar-thumb {
  background-color: rgb(99, 122, 132);
}

/* Admin content **/
/* Sidebar admin content */
.focusElementContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: transparent;
}

.focusElementContent::-webkit-scrollbar {
  width: 4px;
  border-radius: 2px;
  background-color: #ecf0f4;
}

.focusElementContent::-webkit-scrollbar-thumb {
  background-color: #787a7c;
  margin-right: 3px;
}

/** small ribbon fix **/
.ui.ribbon.label:after {
  border-width: 0 1.5em 1.2em 0 !important;
}

.photoTubeSelected {
  background: rgba(0, 0, 0, 0.15) !important;
}

.photoSelectTube:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}

.greenIcon {
  color: rgb(0, 145, 125);
}

.warningIcon {
  color: rgb(165, 58, 18);
}
.largegreenIcon {
  color: rgb(0, 145, 125);
  font-size: 1.5em;
}
.field {
  width: 100%;
}

.error > .ui.fluid.input > input {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}
.ui.pointing.label,
.ui[class*="pointing above"].label {
  display: none;
}

.greenIconLarge {
  color: rgb(0, 145, 125);
  margin-left: 10px;
}

.ui.slider.checkbox {
  min-height: 1rem !important;
  margin-left: 10px;
}

.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #fff !important;
}

.greenLabel {
  background-color: #098827 !important;
  border-color: #098827 !important;
  color: #fff !important;
}
.redLabel {
  background-color: #ab1a1a !important;
  border-color: #ab1a1a !important;
  color: #fff !important;
}

.Toastify__toast--error {
  background-color: #9f3a38 !important;
  border-radius: 4px !important;
}

.Toastify__toast--success {
  background-color: rgb(82, 164, 93) !important;
  border-radius: 4px !important;
}

.Toastify__toast--warning {
  background-color: #d2b61e !important;
  border-radius: 4px !important;
}

.react-pdf__Page__canvas {
  max-width: 100% !important;
  margin: auto;
}

.selectOnError {
  background: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ui.grid > .row > .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.mainButtonsContainerMobile {
  margin: -2em !important;
  position: relative;
  z-index: 0;
}
.mainButtonsGreenContainerMobile {
  border-radius: 0 !important;
}

.stickyFolderManagerBar {
  width: auto;
  margin-left: -1em;
  margin-right: -1em;
  overflow-x: scroll;
  position: relative;
}
.ui.sticky.fixed.top {
  border-top: 0;
}

@media only screen and (max-width: 767px) {
  .ui.sticky {
    min-width: 100%;
    border-top: 1px solid #ecf0f4;
  }
  .pushable::-webkit-scrollbar-track {
    display: none;
  }

  .pushable::-webkit-scrollbar {
    display: none;
  }

  .pushable::-webkit-scrollbar-thumb {
    display: none;
  }

  .prelevementModeLine {
    border-bottom: 0;
    padding: 5px;
    background-color: rgb(1, 111, 97);
    color: #193449;
  }
  .prelevementModeCard {
    background-color: #cbdc01;
    padding: 5px;
    font-size: 0.8em;
    font-weight: 700;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
    top: 5px;
    left: 5px;
  }

  .editionModeLine {
    border-bottom: 0;
    padding: 5px;
    background-color: rgb(1, 111, 97);
    color: #2f7a72;
  }
  .editionModeCard {
    background-color: #99d8d0;
    padding: 5px;
    font-size: 0.8em;
    font-weight: 700;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
    top: 5px;
    left: 5px;
  }
  .gridWithNoMarginMobile {
    margin: 0 !important;
  }
}

.stickyFolderManagerBar::-webkit-scrollbar-track {
  display: none;
}

.stickyFolderManagerBar::-webkit-scrollbar {
  display: none;
}

.stickyFolderManagerBar::-webkit-scrollbar-thumb {
  display: none;
}

@media only screen and (min-width: 768px) {
  .prelevementModeLine {
    border-bottom: 2px solid #cbdc01;
    color: #193449;
    height: 30px;
  }
  .prelevementModeCard {
    background-color: #cbdc01;
    padding: 5px;
    font-size: 0.8em;
    font-weight: 700;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
  }

  .editionModeLine {
    border-bottom: 2px solid #99d8d0;
    color: #2f7a72;
    height: 30px;
  }
  .editionModeCard {
    background-color: #99d8d0;
    padding: 5px;
    font-size: 0.8em;
    font-weight: 700;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
  }
}
.react-pdf__Page__svg {
  margin: auto !important;
}

.pushable {
  overflow: hidden;
}

.pusher {
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 767px) {
  .mainWrapper {
    padding-bottom: 5em !important;
  }
}

@media only screen and (max-width: 375px) {
  .ui.sticky {
    width: max-content;
  }
}
